import { i18n } from "@lingui/core";
import { storeLogoQuery } from "../../graphql/storeLogo.gql";
import Search from "../search";
// eslint-disable-next-line import/no-cycle
import ActionIcons from "./ActionIcons";
import HeaderLogo from "./HeaderLogo";


// eslint-disable-next-line import/no-default-export
export default function MiddleNavbar({ storeLogo }: storeLogoQuery) {
  return (
    <div className="bg-white h-24 w-full hidden 992:block">
      <div className="flex items-center h-full max-w-970 1170:max-w-1170 justify-between mx-auto">
        <div className="w-[300px] flex flex-row justify-start items-center">
          <ActionIcons />
        </div>
        <Search />
        {/* <SearchBox /> */}
        <div className="flex justify-end 992:w-[300px]">
          <button type="button" title={i18n._('Ordme specialized reference for cosmetics, health and wellness products.')} >
          <HeaderLogo storeLogo={storeLogo} />
          </button>
        </div>
      </div>
    </div>
  )
}